import React, { useState } from 'react';

function SearchBar({ onSearch }) {
  const [query, setQuery] = useState('');

  const handleChange = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);

    if (newQuery.length >= 3) {
      onSearch(newQuery);
    }
  };

  return (
    <div>
      <input
        className="SearchingBar"
        type="text"
        placeholder="Buscar"
        value={query}
        onChange={handleChange}
      />
    </div>
  );
}

export default SearchBar;