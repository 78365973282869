import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Loading from "../views/Loading";
import SearchBar from '../components/SearchBar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useLocation, useNavigate } from 'react-router-dom';
import littleMeli from"../assets/images/Logo-little.png";
import checkIcon from"../assets/images/check_icon.png";
import axios from 'axios';

import "../assets/css/ProductList.css";

export default function ProductList() {

    const navigate = useNavigate();
    const location = useLocation();

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [results, setResults] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [isSelectedItems, setIsSelectedItems] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedName, setSelectedName] = useState(null);

    const default_products=[
        "MLM1938026749","MLM827016301","MLM724022764","MLM1513805079",
        "MLM822384550", "MLM1961145993", "MLM1519430457", "MLM2600290336",
        "MLM1957768563", "MLM2531537200", "MLM2581457274", "MLM1390018837",
        "MLM1947260315", "MLM1516321724", "MLM1935323523", "MLM1949824903",
        "MLM1946008848", "MLM682098968", "MLM1323113455"
    ];

    useEffect(() => {
        //searchProducts("catrina");
        fetchProductDetails(default_products);
        setResults(0);
        if (location.state && location.state.selectedItems) {
            setSelectedItems(location.state.selectedItems);
        } else {
            setSelectedItems([]);
        }

        if (location.state && location.state.isSelectedItems) {
            setIsSelectedItems(location.state.isSelectedItems);
        } else {
            setIsSelectedItems(false);
        }

        if(location.state.selectedImage && location.state.selectedName){
            setSelectedImage(location.state.selectedImage);
            setSelectedName(location.state.selectedName);
        }else{
            navigate('/user_info');
        }

    }, []);

    if (loading) {
        return <Loading/>;
    }
    
    if (error) {
        return <div>Error: {error.message}</div>;
    }

    function searchProducts(data) {
        axios.get(`https://api.mercadolibre.com/sites/MLM/search?q=${data}`)
          .then((response) => {
            const productIds = response.data.results.map((item) => item.id);
            fetchProductDetails(productIds);
            setResults(response.data.results.length);
            setLoading(false)
          })
          .catch((err) => {
            setError(err);
            setLoading(false);
          });
    }

    function fetchProductDetails(productIds) {
        const productDetailsPromises = productIds.map((productId) =>
          axios.get(`https://api.mercadolibre.com/items/${productId}`)
        );
    
        Promise.all(productDetailsPromises)
          .then((responses) => {
            const productDetails = responses.map((response) => {
                console.log(response.data);
                return response.data;
            });
            setData(productDetails);
            setLoading(false);
          })
          .catch((err) => {
            setError(err);
            setLoading(false);
          });
      }

    const handleSearch = (query) => {
        searchProducts(query);
    };

    const handleAddToWishlist = (item) => {
        const isDuplicate = selectedItems.some((selectedItem) => selectedItem.id === item.id);

        if (!isDuplicate && selectedItems.length < 4) {
            setSelectedItems([...selectedItems, item]);
            handleOpenModal();
        }
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };    

    const handleContinue = () => {
        navigate('/product_confirmation', { state: { selectedItems, selectedImage, selectedName } });
    }

    const handleRemove = (item) => {
        const newSelectedItems = selectedItems.filter((selectedItem) => selectedItem.id !== item.id);
        setSelectedItems(newSelectedItems);
    }

    return (
        <>
            <div className="SearchHeader">
                <div className="smallLogo">
                    <img src={littleMeli} alt="logo" className="littleMeli"/>
                </div>
                <div className="BarSearch">
                    <SearchBar onSearch={handleSearch} />
                </div>
            </div> 
            {(isSelectedItems) && (
            <div>
                <div className="resultsText">
                    Total results: {results}
                </div>
                <div className="selectedContent">
                    <h2 className="selectedTitle">Productos seleccionados:</h2>
                        {selectedItems.map((item) => (
                            <div key={item.id}>
                                <div className="resultContent" style={{ padding: 20, color:'black', cursor:'pointer',}}>
                                    <Box 
                                        className="imageResult"
                                        component="img" 
                                        alt="" 
                                        src={item.pictures[0].url}
                                        sx={{ width: '100%', height: '100%' }} 
                                    />
                                    <div className="dataResult">
                                        <Typography className="descriptionProduct" style={{ paddingTop: '5px', color:'black' }} >
                                            {item.title}
                                        </Typography>
                                        {/*
                                        <div style={{ paddingTop: '5px', color:'black' }} >
                                            Ranking: 
                                        </div>
                                        */}
                                        <button
                                            className="addButton"
                                            onClick={() => handleRemove(item)}
                                        >
                                            Quitar del Wishlist
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div>
                            {selectedItems.length === 4 && (
                                <button className="contSharebtn" onClick={handleContinue}>Volver y compartir</button>
                            )}
                        </div>
                </div>
            </div>
            )}

            <div>
                {
                    data ? (
                    data.map((item) => {
                        return (
                            <div key={item.id}>
                                <div className="resultContent" style={{ padding: 20, color:'black', cursor:'pointer',}}>
                                    <Box 
                                        className="imageResult"
                                        component="img" 
                                        alt="" 
                                        src={item.pictures[0].url}
                                        sx={{ width: '100%', height: '100%' }} 
                                    />
                                    <div className="dataResult">
                                        <Typography className="descriptionProduct" style={{ paddingTop: '5px', color:'black' }} >
                                            {item.title}
                                        </Typography>
                                        {/*
                                        <div style={{ paddingTop: '5px', color:'black' }} >
                                            Ranking: 
                                        </div>
                                        */}
                                        <button
                                            className="addButton"
                                            onClick={() => handleAddToWishlist(item)}
                                            disabled={selectedItems.includes(item) || selectedItems.length >= 4}
                                        >
                                            Agregar al wishlist
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    ) : (
                        <div>No hay resultados</div>
                    )
                }
            </div>
            <Dialog 
                open={isModalOpen} 
                onClose={() => {}}
            >
                <div className="modalFormat">
                    <img src={checkIcon} alt="check" className="checkadded"/>
                    <DialogTitle className="modalTitle">Producto agregado a tu altar</DialogTitle>
                    <DialogContent className="modalText">
                        {selectedItems.length} / 4 eleccionados: 
                    </DialogContent>
                    <DialogActions>
                        <div className="btnsContent">
                            {selectedItems.length < 4 && (
                                <button className="modalBtnCont" onClick={handleCloseModal}>CONTINUAR BUSCANDO</button>
                            )}
                            {selectedItems.length === 4 && (
                                <button className="modalBtnCont" onClick={handleContinue}>Continuar</button>
                            )}
                        </div>    
                    </DialogActions>
                </div>    
            </Dialog>
        </>
    );
}