import React from "react";
import { Link } from "react-router-dom";
import MainContainer from "../components/MainContainer";
import logoMeli from"../assets/images/logo_meli.png";
import "../assets/css/General.css";
import "../assets/css/StartPage.css";

export default function StartPage() {
    return (
        <MainContainer>
                <div className="logoContainer">
                    <img src={logoMeli} alt="logo" className="logoMeli"/>
                </div>
                <div>
                    <p className="mainTitle">Wishlist del más allá</p>
                </div>
                <div>
                    <p className="mainDescription">
                        Un servicio para que tus seres queridos sepan qué ponerte en el altar y no vengas a jalarles las patas cuando mueras
                    </p>
                </div>
                <div className="btnContainerStart">
                    <button className="mainBtn">
                        <Link 
                            to={{
                                pathname: "/user_info",
                            }}
                            style={{ textDecoration: 'none', color: 'white' }}
                        >
                            Comenzar
                        </Link>
                    </button>
                </div>
                <br/>
                <br/>
        </MainContainer>
    );
}