import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import StartPage from "./views/StartPage";
import UserInfo from "./views/UserInfo.js";
import UserConfirmation from "./views/UserConfirmation";
import ProductList from "./views/ProductList";
import ProductConfirmation from "./views/ProductConfirmation";
import Loading from "./views/Loading";
import ProductShare from "./views/ProductShare";


import './App.css';
import firebaseApp from './firebaseConfig';

function App() {
  return (
      <Router>
        <Routes>
            <Route path="/" element={<StartPage/>}/>
            <Route path="/user_info" element={<UserInfo/>}/>
            <Route path="/user_confirmation" element={<UserConfirmation/>}/>
            <Route path="/product_list" element={<ProductList/>}/>
            <Route path="/product_confirmation" element={<ProductConfirmation/>}/>
            <Route path="/loading" element={<Loading/>}/>
            <Route path="/product_share" element={<ProductShare/>}/>
        </Routes>
      </Router>
  );
}

export default App;