import React from "react";
import loadingFlower from"../assets/images/loading_flor.png";
import MainContainer from "../components/MainContainer";
import "../assets/css/General.css";
import "../assets/css/Loading.css";

export default function Loading() {
    return (
        <div className="loadingBackground">
            <>
                <div className="loadingItem">
                    <img src={loadingFlower} alt="loading" className="loading-flower"/>
                </div>
            </>
        </div>
    );
}