import React, { useState, useEffect } from 'react';
//import { Link } from "react-router-dom";
import MainContainer from "../components/MainContainer";
//import Frame from"../assets/images/photo_confirm.png";
import { useLocation, useNavigate } from 'react-router-dom';
import "../assets/css/UserConfirm.css";
import CleanFrame from"../assets/images/old_frame.png";

export default function UserInfo() {

    const navigate = useNavigate();
    const location = useLocation();

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedName, setSelectedName] = useState(null);

    useEffect(() => {
        if(location.state.selectedImage && location.state.selectedName){
            setSelectedImage(location.state.selectedImage);
            setSelectedName(location.state.selectedName);
        }else{
            navigate('/user_info');
        }
    }, []);

    const handleContinue = () => {
        navigate('/product_list', { state: {selectedImage, selectedName} });
    };

    return (
        <div className="UserInfoContainer">
            <>
                <div className="frameContainer">
                    <img src={selectedImage} alt="frame" className="imgFrame"/>
                    {selectedImage && (
                        <div className="cleanFrameConfirm">
                            <img src={CleanFrame} alt="cleanframe" className="photoFrame"/>
                        </div>
                    )}                
                </div>
                <div>
                    <p className="welcomeText">¡Bienvenido, {selectedName}!</p>
                </div>
                <div>
                    <p className="confirmDescription">
                        ¿Estás listo para armar tu wishlist del más allá?
                    </p>
                </div>
                <div className="btnContainerConfirm">
                    <button className="mainBtn" onClick={handleContinue}>
                        Comenzar
                    </button>
                </div>            
                <br/>
                
            </>
        </div>          
    );
}