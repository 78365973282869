import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainContainer from "../components/MainContainer";
import html2canvas from 'html2canvas';
import CleanFrame from"../assets/images/old_frame.png";
import firebaseApp from "../firebaseConfig"
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import littleMeli from"../assets/images/Logo-little.png";

import "../assets/css/ProductConfirmation.css";
import "../assets/css/ProductShare.css";

export default function ProductConfirmation() {

    const storage = getStorage(firebaseApp);

    const navigate = useNavigate();
    const location = useLocation();
    const { selectedItems } = location.state;
    const [isSelectedItems, setIsSelectedItems] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedName, setSelectedName] = useState(null);
    const [screenshot, setScreenshot] = useState(null);
    const [selectedItemBlobs, setSelectedItemBlobs] = useState([]);
    const [isCapturing, setIsCapturing] = useState(false);


    useEffect(() => {
        if (selectedItems.length > 0 && !isSelectedItems && selectedItems.length === 4){
            setIsSelectedItems(true);
        }else{
            setIsSelectedItems(false);
            navigate('/product_list', { state: { selectedItems, isSelectedItems } });
        }

        if(location.state.selectedImage && location.state.selectedName){
            setSelectedImage(location.state.selectedImage);
            setSelectedName(location.state.selectedName);
        }else{
            navigate('/user_info');
        }

        const imageBlobs = selectedItems.map(async (item) => {
            let img_url = item.pictures[0].url;
            img_url = img_url.replace(/http:/g, 'https:');
            const response = await fetch(img_url);
            const blob = await response.blob();
            return blob;
        });

        Promise.all(imageBlobs).then((blobs) => {
            setSelectedItemBlobs(blobs);
        });        

    }, []);

    function handleEditItems(){
        setIsSelectedItems(true);
        navigate('/product_list', { state: { isSelectedItems, selectedItems, selectedImage, selectedName } });
    }

    async function captureScreen() {
        try {            
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
    
            const canvas = document.createElement('canvas');
            canvas.width = document.body.scrollWidth; // Ancho de la página completa
            canvas.height = document.body.scrollHeight; // Alto de la página completa

            const dpi = window.devicePixelRatio || 1;
            canvas.style.width = `${canvas.width / dpi}px`;
            canvas.style.height = `${canvas.height / dpi}px`;
            canvas.width *= dpi;
            canvas.height *= dpi;            

            const screenShot = await html2canvas(document.body, {
                canvas: canvas,
                width: document.body.scrollWidth,
                height: document.body.scrollHeight,
                scale: dpi, 
            });
            return screenShot.toDataURL('image/jpeg');
            
        } catch (error) {
            console.error("Error al tomar la captura de pantalla:", error);
            return null; // O cualquier otro valor predeterminado
        }
    }


    const shareImage = async () => {
        if (screenshot) {
            // Realiza la lógica de compartir aquí
            alert('Compartiendo captura de pantalla...');
        } else {
            alert('La captura de pantalla aún no se ha completado.');
        }
    
        /*
        let url = "https://www.google.com/";
        let title = "Meli";
        let text = "Meli text";
        const imageUrl = 'https://st.depositphotos.com/2274151/3518/i/450/depositphotos_35186549-stock-photo-sample-grunge-red-round-stamp.jpg'; // Reemplaza 'URL_DE_LA_IMAGEN' con la URL de tu imagen
        let shareDetails = { url, text, files:[imageUrl]};

        if (navigator.share) {
            try {
              await navigator
                .share(shareDetails)
                .then(() =>
                  alert("Hooray! Your content was shared to tha world")
                );
            } catch (error) {
              alert(`Oops! I couldn't share to the world because: ${error}`);
            }
          } else {
            // fallback code
            alert("Web share is currently not supported on this browser. Please provide a callback");
        }
        */
    };

    async function handleConfirmProducts(){
            if (isCapturing) {
                // Si ya está capturando, no hagas nada
                return;
            }
        
            setIsCapturing(true);
            setTimeout(async () => {
            const capturedScreenshot = await captureScreen();
                setScreenshot(capturedScreenshot);
                setIsCapturing(false);
                navigate('/product_share', { state: { isSelectedItems, selectedImage, selectedItemBlobs, capturedScreenshot} });
            }, 200);
            
        
    }

    return (
        <div className="confirmContainer">
            <>
                <div style={{minHeight: '30vh', position: 'relative' }}>
                    <div className="containerFrameConfirm">
                        <div style={{width: '37%', margin: 'auto', display: 'block' }}>
                            <img src={selectedImage} alt="frame" className="photoImg"/>
                        </div>    
                        <div className="frameVintage">
                            <img src={CleanFrame} alt="cleanframe" className="vintageEffect"/>
                        </div>
                    </div>
                </div>    
                {isCapturing && (
                    <p className="textAltar">"Si no quieres que te venga a jalar las patas, pon en el altar lo que mas me encanta"</p>
                )}
                <div className="gridResults"> {/*Images container*/}
                    {selectedItemBlobs.map((blob, index) => (
                        <div className="selectedItems" key={selectedItems[index].id}>
                            <img
                                src={URL.createObjectURL(blob)}
                                alt=""
                                style={{marginLeft: 'auto', marginRight: 'auto', display: 'block', maxHeight: '100%', maxWidth: '100%' }}
                            />
                        </div>
                    ))}
                </div>
                {isCapturing && (
                    <><br/><br/><br/><br/></>
                )}
                {!isCapturing && (
                    <div>
                        <button className="mainBtn" onClick={handleConfirmProducts}>{isCapturing ? 'Capturando...' : 'Confirmar'}</button>
                    </div>
                )}
                {!isCapturing && (
                    <div className="editProductsText">
                        <p onClick={() => handleEditItems()} >Editar tu altar</p>
                    </div>
                )}
                {false && (
                    <div className="littleLogoCont">
                        <img src={littleMeli} alt="logo" className="melittle"/>
                    </div>
                )}
            </>
        </div>    
    );
}