import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MainContainer from "../components/MainContainer";
import CleanFrame from"../assets/images/old_frame.png";
import firebaseApp from "../firebaseConfig"
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import { saveAs } from 'file-saver';

import "../assets/css/ProductConfirmation.css";
import "../assets/css/ProductShare.css";

export default function ProductShare() {

    const storage = getStorage(firebaseApp);
    const location = useLocation();
    const { selectedImage, selectedItemBlobs, capturedScreenshot} = location.state;

    useEffect(() => {
    }, []);

    async function firebaseStore() {
        let downloadURL = ""; 
        try {
            const uniqueFileName = `${Date.now()}_${Math.random().toString(36).substring(2, 15)}.jpg`;
            const storageRef = ref(storage, `/screenshots/${uniqueFileName}`);
            // 'file' comes from the Blob or File API
            await uploadString(storageRef, capturedScreenshot, 'data_url'); 
            downloadURL = await getDownloadURL(storageRef);
            //console.log('Uploaded a blob or file!' + downloadURL);
            downloadURL = btoa(downloadURL);
            downloadURL = 'http://wishlist.wishlistdelmasalla.mx/?url=' + downloadURL;
            console.log(downloadURL);
          } catch (error) {
            console.log('Error al cargar el screenshot en Firebase:', error);
          }
          return downloadURL;
      
    }

    async function handleShare(){
        const downloadURL = await firebaseStore();
        console.log(downloadURL);

        let url = downloadURL;
        let title = "Mercado Libre";
        let text = "Mira lo que me gustaría que pusieras en mi altar, o prepárate para que en el futuro te venga a jalar las patas.";
        let shareDetails = { url, title, text, };

        if (navigator.share) {
            try {
              await navigator
                .share(shareDetails)
                .then(() =>
                  console.log("Hooray! Your content was shared to tha world")
                );
            } catch (error) {
              console.log(`Oops! I couldn't share to the world because: ${error}`);
            }
        } else {
            // fallback code
            alert("El navegador no soporta compartir");
        }
    }

    const downloadImage = () => {
    saveAs(capturedScreenshot, 'imagen.png');
    };
    

    return (
        <div className="shareBackground">
            <>
                <div style={{minHeight: '30vh', position: 'relative' }}>
                    <div className="containerFrameConfirm">
                        <div style={{width: '40%', margin: 'auto', display: 'block' }}>
                            <img src={selectedImage} alt="frame" className="photoImg"/>
                        </div>    
                        <div className="frameVintage">
                            <img src={CleanFrame} alt="cleanframe" className="vintageEffect"/>
                        </div>
                    </div>
                </div>
                <div style={{paddingTop: '30px' }}>
                    <p className="textAltar">"Si no quieres que te venga a jalar las patas, pon en el altar lo que mas me encanta"</p>
                    <div className="gridResults">
                        {selectedItemBlobs.map((blob, index) => (
                            <div className="selectedItems">
                                <img
                                    src={URL.createObjectURL(blob)}
                                    alt=""
                                    style={{marginLeft: 'auto', marginRight: 'auto', display: 'block', maxHeight: '100%', maxWidth: '100%' }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='downBtnCont'>
                    <button className="mainBtn" onClick={downloadImage}>Descarga y comparte</button>
                </div>                
                <div className='shareBtnCont'>
                    <button className="mainBtn" onClick={handleShare}>Envía directo a un amigo</button>
                </div>
            </>
        </div>
    );
}