import React, { useState } from 'react';
import { Container} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MainContainer from "../components/MainContainer";
import Frame from"../assets/images/frame-02.png";
import CleanFrame from"../assets/images/frame_clean.png";
import "../assets/css/General.css";
import "../assets/css/UserForm.css";
import "../assets/css/UserConfirm.css";

export default function UserInfo() {

    const navigate = useNavigate();

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedName, setSelectedName] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleChange = (event) => {
        const newQuery = event.target.value;
        setSelectedName(newQuery);
    };

    const handleConfirmation = () => {
        navigate('/user_confirmation', { state: {selectedImage, selectedName} });
    };

    return (
        <div className="UserInfoContainer">
            <>
                <Container>
                    <div className="photoContainer">
                        <label htmlFor="imageInput" className="image-upload-label">
                        {selectedImage ? (
                                <img src={selectedImage} alt="avatar" className="frame"/>
                            ) : (
                            <img src={Frame} alt="frame" className="frame" />
                        )}
                        </label>
                        <input
                            type="file"
                            id="imageInput"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                        />
                        {selectedImage && (
                            <div className="cleanFrameConfirm">
                                <img src={CleanFrame} alt="cleanframe" className="cleanframe" />
                            </div>
                        )}
                    </div>

                    <div>
                        <p className="welcomeText">¡Hola {selectedName}!</p>
                    </div>
                    <div className="inputContainer">
                        <input className="nameInput" type="text" placeholder="ESCRIBE TU NOMBRE" onChange={handleChange}/>
                    </div>
                    <div className="btnContainerUser">
                        <button className="mainBtn" onClick={handleConfirmation}>
                            Comenzar
                        </button>
                    </div>
                </Container>
            </>
        </div>    
    );
}