import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyC4Y1EKQ5eEpPNYLJDfGMrPXzilKhmOm7E",
    authDomain: "meli-65333.firebaseapp.com",
    projectId: "meli-65333",
    storageBucket: "meli-65333.appspot.com",
    messagingSenderId: "1079849028140",
    appId: "1:1079849028140:web:569c1c24ea47dc0d109f89",
    measurementId: "G-TW09CFQPN9"
};

const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;